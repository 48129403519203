<template>
  <div class="change-info">
    <h1>Change information</h1>
    <div class="upload-file">
      Agency photo:
      <UploadSingleImg v-model="headIcon" :on-upload="uploadFile" />
    </div>
    <el-link type="danger" class="top-line" :underline="false">* Agent photos and names will be displayed on the agent
      ranking list</el-link>
    <div class="btn-group">
      <el-button type="primary" @click="updateSelfInfo">Submit</el-button>
      <el-button @click="resetImg">Reset</el-button>
    </div>
  </div>
</template>
<script>
import UploadSingleImg from '@/components/UploadSingleImg/UploadSingleImg'
import { uploadImgReq, updateAgentInfo } from '@/api/avatar'
export default {
  components: {
    UploadSingleImg
  },
  data() {
    return {
      headIcon: ''
    }
  },
  methods: {
    async uploadFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('cover', 0)
      formData.append('dir', 'agent/')
      const { data: res } = await uploadImgReq(formData)
      return { code: res.code, url: res.data }
    },
    resetImg() {
      const avatar = this.$store.state.headIcon;
      console.log(this.$store.state.headIcon);
      this.headIcon = avatar || '';

    },
    async updateSelfInfo() {
      if (this.headIcon === '') return this.$message.warning('please upload avatar')
      const res = await updateAgentInfo({
        agentId: JSON.parse(sessionStorage.getItem('loginData')).agentId,
        headIcon: this.headIcon
      })
      if(res.code === 0){
        this.$message.success('change success')
        this.$store.commit('setHeadIcon', this.headIcon)
        window.sessionStorage.setItem('headIcon',JSON.stringify(this.headIcon))
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  mounted() {
    this.resetImg()
  }
}
</script>
<style scoped lang="scss">
.change-info {
  position: relative;
}

.upload-file {
  margin: 40px 0;
}

.btn-group {
  margin-top: 20px;
}

.top-line {
  position: absolute;
  left: 500px;
  top: 70px;

}
</style>