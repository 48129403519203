import request from '@/utils/request'

// 上传图片
export function uploadImgReq(formData) {
  return request.post('/agent_admin/aws/file/upload', formData)

}


export async function updateAgentInfo(data) {
  const { data: res } = await request({
    url: '/agent_admin/agency/update_head',
    method: 'post',
    data
  })
  return res
}