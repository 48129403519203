<template>
  <div class="upload-single-img">
    <el-button type="success" v-if="canUpload" v-loading="loading">
      <input
        type="file"
        class="upload-real"
        ref="fileEle"
        required
        @change="uploadImg"
      />
      <i class="el-icon-upload"></i>
      upload
    </el-button>
    <div class="upload-image" v-else>
      <el-image
        style="width: 100px; height: 100px"
        :src="value"
        :preview-src-list="[value]"
      >
      </el-image>
      <div class="mask">
        <i class="el-icon-delete" style="color: #fff; cursor: pointer;font-size: 18px;" @click="deleteUrl"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    onUpload: Function,
    onDelete: Function,
    value: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      loading: false,
      canUpload: true
    };
  },
  methods: {
    async uploadImg(file) {
      this.loading = true
      const res = await this.onUpload(this.$refs.fileEle.files[0])
      if(res.code === 0){
        this.$message.success('upload success')
        if(res.url){
          this.$emit('input', res.url)
        } else {
          this.$emit('input', URL.createObjectURL(this.$refs.fileEle.files[0]))
        }
      } else {
        this.$message.error(res.msg)
      }
      this.loading = false
    },
    deleteUrl(){
      this.$emit('input', '')
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.canUpload = true;
        } else {
          this.canUpload = false;
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.upload-single-img {
  position: relative;
  display: inline-block;
}
.upload-real {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.upload-image {
  position: relative;
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    z-index: 99;
    top: 0;
    left: 0;
    // display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }
  &:hover > .mask {
    display: flex;
  }
}
</style>
